.product-input_block {
  padding-top: 12px;
  padding-bottom: 12px;

  &.left {
    padding-right: 12px;
  }


}
.product-item_picture {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;

  img {
    height: 200px;
    max-width: 100%;
  }

  .picture-delete {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}