.mix-text (@size, @weight: 600, @color: #3F3D56, @height: 1.6) {
  font-style: normal;
  font-weight: @weight;
  font-size: @size;
  line-height: @height;
  letter-spacing: 0.2px;
  color: @color;
}

.flex (@align: center, @justify: flex-start, @gap: 0, @flow: row nowrap) {
  display: flex;
  align-items: @align;
  justify-content: @justify;
  gap: @gap;
  flex-flow: @flow;
}

.grid (@template: 1fr, @align: center, @gap: 0){
  display: grid;
  grid-template-columns: @template;
  align-items: @align;
  gap: @gap;
}