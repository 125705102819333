html {
  overflow-x: visible !important;
}

.zone {
  position: relative;

  .buttons {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }
}