.footer {
  margin-top: 30px;
  background: #111;

  .logo {
    color: white;
    padding: 30px 0;

    p {
      font-size: 24px;
      font-weight: bold;
    }
    span {
      display: block;
      font-size: 12px;
      font-weight: 400;
      margin-top: -20px;
    }
  }
  .icons {
    text-align: right;
    padding: 30px 0;
  }
}