.signin {

  .logo {
    width: 120px;

    @media(max-width: 600px) {
      width: 80px;
    }
  }
  h1 {
    font-size: 36px;
    margin-top: 15px;

    @media(max-width: 600px) {
      font-size: 26px;
    }

    strong {
      font-weight: 800;
    }
  }
  .input-block {
    margin: 10px 10px 10px 0;

    .signin_input {
      padding: 12px;
      height: auto;
    }
  }
  .input-block-button {
    margin: 0px 10px 10px 0;

    button {
      padding: 12px;
      height: auto;
    }
  }
}