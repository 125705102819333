@import '../mix/mixins';

.product {
  &__images {
    .grid(repeat(5, 1fr), start, 10px);
  }

  &__image {
    position: relative;
    height: 100%;
    box-shadow: 0 0 10px rgba(black, 0.08);
    border-radius: 15px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
    }

    &-first {
      position: absolute;
      left: 10px;
      top: -15px;
      padding: 5px 12px;
      background: @primary-color;
      .mix-text(12px, 600, #fff, 1);
      border-radius: 20px;
    }

    &-hasVideo {
      position: absolute;
      top: 5px;
      right: 5px;
      background: #fff;
      font-size: 26px;
      border-radius: 50%;
    }

    &-buttons {
      position: absolute;
      left: 0;
      bottom: 5px;
      padding: 0 5px;
      .flex(center, space-between, 5px, row nowrap);
      opacity: 0;
      visibility: hidden;
      transition: .3s ease;
    }

    &:hover {
      .product__image-buttons {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}