@import '../mix/mixins';

.designer {
  &__block {
    width: 100%;
    padding: 12px 20px;
    .flex(center, flex-start, 15px, row wrap);
    border: 1px dashed #e1e1e1;
    border-radius: 15px;
    transition: .3s ease;

    .block {
      &__title {
        flex: 0 0 60%;
        .mix-text(16px, 600, #000, 1);
      }

      &__actions {
        flex: 0 0 calc(40% - 15px);
        .flex(center, flex-end, 10px, row nowrap);
      }

      &__elements {
        flex: 1 0 100%;
        width: 100%;

        &-files {
          .grid(repeat(2, 1fr), start, 10px);
        }
      }

      &__name {
        margin-bottom: 7px;
        &:only-child {
          margin-bottom: 0;
        }
      }

      &__refer,
      &__type {
        .mix-text(12px, 400, #808080, 1);
      }

      &__file {
        width: 100%;
        .flex(center, flex-start, 15px, row nowrap);

        .file {
          &__icon {
            flex: 0 0;
            color: @primary-color;
            font-size: 32px;
          }

          &__name {
            flex: 1 0;
            .mix-text(14px, 400, #000, 1.3);

            a {
              color: #000;
              transition: .3s ease;
              &:hover {
                color: @primary-color;
              }
            }
          }
        }

        .block__actions {
          flex: 0 0;
        }
      }
    }

    &-opened {
      border-color: #000;
    }

    &-withImage {
      .block {
        &__image {
          flex: 0 0 10%;
          border-radius: 15px;
          overflow: hidden;
        }

        &__title {
          flex: 0 0 calc(50% - 15px);
        }
      }
    }

    &-file {
      &:hover,
      &_editing {
        border-color: #000;
      }
    }
  }
}