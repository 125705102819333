@import '../mix/mixins';

.point {
  &__name {
    margin-bottom: 5px;
    .mix-text(16px, 600, #000, 1);
  }

  &__address {
    .mix-text(12px, 400, #808080, 1);
  }
}