.navigation {
  display: block;
  width: 100%;
  background-color: #F5F5F8;
  border-radius: 15px;
  padding: 15px;

  .navigation-rel {
    position: relative;

    .navigation_element {
      border-radius: 8px;
      padding: 8px 15px;
      margin-bottom: 3px;
      width: 100%;

      span {
        font-size: 13px;
        margin-right: 10px;
        width: 30px;
        color: rgba(0, 0, 0, 0.7);
      }

      &.navigation_active {
        color: @primary-color;
      }

      .icon {
        max-width: 30px;
      }
    }




  }

  hr {
    border-top: 1px solid #D4D3E3;
    margin: 20px auto;
  }

  span.titles {
    position: absolute;
    top: -11px;
    left: 20px;
    padding: 0 5px;
    background: #F5F5F8;
    font-weight: 500;
    color: #757575;
  }

  ul {
    padding: 0;

    li {
      list-style-type: none;

      a {
        display: block;
        width: 100%;
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.4px;
        color: #3F3D56;
        white-space: nowrap;



        .inline {
          display: flex;
        }


        &.active {
          color: @primary-color !important;

          svg {
           color: @primary-color;
          }

          .point {
            color: @primary-color !important;
          }
        }
      }
    }
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #2175B0;