@import '../mix/mixins';

.order {
  &__block {
    height: 100%;
    padding: 30px;
    .flex(flex-start, flex-start, 20px, column);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(black, 0.08);

    &-item {
      width: 100%;

      .item {
        &__heading {
          margin-bottom: 5px;
          .flex(center, flex-start, 40px, row nowrap);
          .mix-text(22px, 700, #3F3D56, 1.3);
        }

        &__text {
          .mix-text(16px, 400, #3F3D56, 1.3);

          &-bold {
            font-weight: 600;
          }
        }
      }
    }

    .ant-select {
      width: 100%;
    }

    .ant-divider {
      margin: 0;
    }
  }

  &__products {
    .flex(flex-start, flex-start, 20px, column);

    .item {
      width: 100%;
      .grid(.7fr 4fr 1fr 2fr, center, 30px);
      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px dashed #e1e1e1;
      }

      &.item-is_set {
        border-color: transparent !important;
      }

      &-editable {
        grid-template-columns: .7fr 4fr 1fr 2fr 1fr;
      }

      &__meta {
        .flex(flex-start, flex-start, 5px, column);

        &-vendor {
          .mix-text(12px, 400, #757575, 1);
        }

        &-name {
          .mix-text(18px, 700, #3F3D56, 1.3);
        }

        &-props {
          .flex(flex-start, flex-start, 5px, row wrap);

          .ant-tag {
            margin-inline-end: 0;
          }
        }
      }

      &__quantity {
        .flex(center, center, 5px, row nowrap);

        & > * {
          flex: 0 0 auto;
        }
      }

      &__price {
        .mix-text(18px, 700, #3F3D56, 1.3);
        text-align: right;
      }
    }
  }
}