.form {
  height: 100vh;

  @media(max-width: 600px) {
    margin-top: 100px;
  }

  .center {
    text-align: center;

    .type {
      background: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 7px 12px;
      display: inline-block;
      width: auto;
      border-radius: 5px;
    }
  }
  h1 {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 24px;

    @media(max-width: 600px) {
      font-size: 26px;
      line-height: 1.2;
    }
  }
  .step {
    background: #e1e1e1;
    border-radius: 5px;
    display: inline-block;
    padding: 4px 10px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h5 {
    margin-bottom: 24px;
    margin-top: -24px;
  }
  .inputs {
    padding-bottom: 9px;

    &.all {
      input {
        max-width: 280px;
      }
    }

    &.signup {
      padding: 0 4px 9px 4px;
    }

    input {
      background: #F8F8F8;
      border-radius: 6px;
      border: 2px solid #E7E7E8;
      padding: 16px 24px;
      line-height: 1;
      font-size: 15px;
      font-weight: 500;
      width: 100%;
      outline: none;
      transition: all 1s;

      &:focus {
        border: 2px solid #A0A0A2;
        transition: all 1s;
      }

      &:disabled {
        opacity: 0.4;
        transition: all 1s;
      }
    }
  }
  .forgots {
    padding-top: 10px;

    p {
      font-weight: 600;
    }
  }
  .draggers {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
  }
  .buttons {
    padding-top: 20px;

    &.all {
      input, .button, .signup {
        max-width: 280px;
        transition: all 0.3s;

        &:hover {
          max-width: 320px;
          transition: all 0.3s;
        }

        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }

    .signup {
      display: inline-block;
      background: rgba(231, 231, 232, 0.57);
      outline: none;
      color: rgb(32, 35, 39);
      border: none;
      border-radius: 500px;
      padding: 13px;
      width: 100%;
      font-weight: 600;
      font-size: 17px;
      transition: all 0.3s;
      margin-top: -40px;

      &:hover {
        cursor: pointer;
        transition: all 0.3s;
      }
    }

    input, .button {
      display: inline-block;
      background: #63D498;
      outline: none;
      color: white;
      border: none;
      border-radius: 500px;
      padding: 13px;
      width: 100%;
      font-weight: 600;
      font-size: 17px;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        transition: all 0.3s;
      }
    }
  }
  .bandle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: #63D498;
    border-radius: 500px;

    img {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .preview {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &:hover {
      cursor: pointer;
    }
  }
  .select-element {
    padding: 5px;
  }
  .select-module {
    background: #ebecec;
    border-radius: 5px;
    padding: 15px;

    &.selected {
      background: #63D498;
      color: white;
    }

    &:hover {
      cursor: pointer;
    }

    p {
      font-weight: 800;
      font-size: 20px;
      line-height: 1;
    }
    span {
      font-size: 14px;
    }
  }
}