@import '../mix/mixins';

.components-block {
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 30px 30px 10px;
  position: relative;

  div.switch {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-title {
    .mix-text(18px, 600);
  }

  p.label {
    .mix-text(14px, 500, #9D9EA9, 1.4);
  }
}