@import "antd/dist/reset.css";
@import '../fonts/stylesheet.css';
// @import '~antd/dist/antd';
@import './old/template';

@import './old/header';
@import './old/signin';
@import './old/template';
@import './old/category';
@import './old/footer';
@import './old/delivery';
@import './old/product';
@import './old/form';
@import './old/zone';

@import './components/block';
@import './var';

@import './components/__components';

//@import '@tiptap/starter-kit/';
//@import '@tiptap/starter-kit/dist/css/bubble-menu.css';
//@import '@tiptap/starter-kit/dist/css/floating-menu.css';

body {
  scroll-behavior: smooth;
}

p.green {
  color: green;
}

p.red {
  color: red;
}

.interval_day {
  border: 1px dashed #e1e1e1;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;

  .interval_day_name {
    font-size: 22px;
    font-weight: bold;
  }

  .interval_day_type {
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    color: var(--primary-color);
  }
}

.user_orders_cart {
  border-radius: 30px;
  padding: 30px 30px 30px;
  background: rgba(@bg-color, 0.9);
  margin-bottom: 50px;

  p.orders {
    font-size: 28px;
    font-weight: 800;
    color: rgba(#ffffff ,0.9);
  }

  p.detail_title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(#ffffff, 0.7);
    line-height: 1.5;
  }
  p.detail_value {
    font-size: 20px;
    font-weight: 800;
    line-height: 1;
    color: rgba(#ffffff ,0.9);
  }
}

.user_detail_cart {

  p.orders {
    font-size: 28px;
    font-weight: 800;
  }

  p.orders_all {
    font-size: 18px;
    font-weight: 600;

    span {
      font-weight: 800;
      color: var(--primary-color);
    }
  }

  border-radius: 30px;
  padding: 30px 30px 10px;
  background: rgba(#f1f1f1, 0.5);
  margin-bottom: 50px;

  &.dash {
    background: transparent;
    border: 1px solid #f1f1f1;
  }

  .detail_element {
    margin-bottom: 30px;
  }

  p {
    margin: 0;
  }

  p.detail_title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(0,0,0,0.4);
    line-height: 1.5;
  }
  p.detail_value {
    font-size: 20px;
    font-weight: 800;
    line-height: 1;
    color: rgba(0,0,0,0.8);
  }
}


p {
  margin-bottom: 0;
}
//a {
//  color: @text-color;
//}

.default {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.default-content {
  padding: 30px 10px 30px 30px;
  position: relative;

  @media (@xs) {
    padding: 15px;
    margin-top: 60px;
  }
}
h1 {
  font-size: 30px;
  font-weight: 800;
  color: #2175B0;
}
h3 {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
}
.default-block {
  background-color: #F5F5F8;
  border-radius: 15px;
  padding: 25px;
}
.default-block-buttons {
  margin-bottom: 30px;
}
.right {
  text-align: right;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 600;

  &.title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &.label {
    margin-bottom: 7px;
  }
}

.setting {
  background: #F5F5F9;
  border-radius: 10px;
  padding: 15px 20px;
  text-align: center;
  min-height: 80px;
  display: flex;
  transition: all 0.2s;

  &.disabled {
    opacity: 0.5;
  }

  h5 {
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    transition: all 0.2s;
    margin-top: -3px;
  }

  h5 {
    margin: auto;
  }
}

.default-flex {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 10px;
  margin-top: 20px;
}

.default-flex-min {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 20px;
}

.schedule {
  border-radius: 5px;
  background: #f5f5f9;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    margin-top: -3px;
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    transition: all 0.2s;
    opacity: 0.5;
  }

  &.active {
    background-color: var(--primary-color);
    color: white;
  }

  p {
    line-height: 40px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
}

.description-webhooks {

  .vars {
    background-color: #f5f5f9;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    padding: 3px;
    border-radius: 3px 6px;
  }

  .el {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
    padding-bottom: 15px;

    &.parent {
      margin-left: 50px;
    }

    p {
      font-weight: 600;
      line-height: 1;

      span {
        opacity: 0.6;
      }

      &.format {
        font-weight: 600;
        font-size: 12px;
      }

      &.type {
        font-weight: 600;
        font-size: 12px;
        opacity: 0.6;
      }
    }
  }
}

ymaps {
  svg {
    border: 2px solid black;
    margin: 0 0;
  }
}




.ymap_block {
  position: relative;
  box-sizing: content-box;
  width: 100%;
  height: 400px;

  .zone {
    position: relative;
    box-sizing: content-box;
    width: 100%;
    height: 400px;
    overflow-y: hidden;
  }
}

.integration {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 9px;
  transition: all 0.5s;

  p {
    margin: 0;
  }
  .name {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.2;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transition: all 0.5s;
  }
}
.default-pin {
  width: 26px;
  height: 26px;
  transform: translate(-50%, -50%);

  svg {
    width: 26px;
    height: 26px;

    path {
      color: var(--primary-color);
    }
  }
}
.App-map {
  height: 400px;
  width: 100%;
}
.pushes_templates {
  margin: 30px 0;
  background: #f1f1f1;
  padding: 30px;
  border-radius: 30px;

  p.title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }

  .element {
    background: white;
    border-radius: 15px;
    padding: 20px;
  }
}

.icon_select {
  svg {
    path {
      fill: #9E9E9E;
    }
  }

  div {
    width: 40px;
    height: 40px;
    position: relative;

    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 500px;
    border: 1px dashed #e1e1e1;
    padding: 10px;
    opacity: 0.6;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
      cursor: pointer;
      transition: opacity 0.5s;
    }

    &.active {
      opacity: 1;
      border-color: var(--primary-color);
      //svg {
      //  path {
      //    opacity: 1;
      //    // fill: var(--primary-color);
      //  }
      //}
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 80%;
      max-height: 80%;
    }
  }
}

label.label_default {
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
}

.selected_elements {
  transition: all 0.5s;
  width: 100%;
  max-width: 1200px;
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 20px auto 0;
  padding: 15px 30px;

  .element {
    background-color: var(--primary-color);
    color: white;
    border-radius: 15px;
    padding: 15px 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  }
}

.editor_textarea {
  font-size: 16px;
}
.editor_link {
  font-size: 18px;
  text-decoration: underline;
  font-weight: 600;
  color: #0b7ad1;
}
.editor_link_link {
  font-size: 14px;
}



.select_account {
  font-size: 22px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  opacity: 0.7;
  transition: opacity 0.3s;
  text-align: left;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
  }
}

.dashboard-alert {
  background: #FFFFFF;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  opacity: 1;
  padding: 15px 20px;
  position: relative;

  &.dashboard-alert-info {
    background: #1976D2;
    color: white;
  }

  &.dashboard-alert-success {
    background: #2E7D32;
    color: white;
  }
}

.opacity_plan {
  opacity: 0.6;
}

.switch_block {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .switch_block_element {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(black, 0.08);
    padding: 7px 9px;

    .right {
      text-align: right;
    }

    p {
      line-height: 1;
      margin: 0;

      &.switch_block_element-title {
        color: rgba(black, 0.8);
        line-height: 1.2;
        font-weight: 500;
        min-width: 100px;
      }

      &.switch_block_element-description {
        font-size: 12px;
        color: rgba(black, 0.6);
        line-height: 1.2;
      }
    }
  }
}

.formLabel {
  margin-bottom: 7px;
}


.product_storage {
  background: rgba(#f1f1f1, 0.5);
  padding: 30px 30px !important;
  border-radius: 15px;

  p.name {
    font-size: 20px;
    font-weight: 600;
  }
}

.switch-block .element {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

p.sub {
  margin: 0;
}

.list_condition {
  width: 100%;
  background: rgba(#f1f1f1, 0.5);
  padding: 30px 30px;
  border-radius: 15px;
}

.cpl__modal {
  .ant-modal-content {

  }

  &-close {
    position: absolute;
    top: 43px;
    right: 24px;
  }
}

.order_header {
  background: #FFFFFF;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding: 30px 40px 10px;
  margin-top: 20px;
}
.order_map {
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding: 0;

  div {
    border-radius: 32px;

    ymaps {
      border-radius: 32px;
    }
  }
}
.order-status {
  border-radius: 17px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  padding: 30px 30px 10px;
  // transition: 3s all;

  .for {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
  }

  .description {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #9D9EA9;
    margin-top: 15px;
    display: block;
  }

  .status_input {
    font-weight: 800;
    font-size: 22px;
    width: 100%;
    outline: none;
    border: none;
  }

  .tops {
    position: absolute;
    top: 30px;
    right: 70px;
    z-index: 2;
    opacity: 1;
    width: 32px;
    height: 32px;
    background: #F5F5F8;
    text-align: center;
    border-radius: 500px;
    transition: 1s all;

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 70%;
    }
  }

  .icon_bg {
    opacity: 0.3;
    background: #F5F5F8;
    text-align: center;
    border-radius: 500px;
    width: 40px;
    height: 40px;
    position: relative;
    transition: 1s all;

    &.selected {
      opacity: 1;
      transition: 1s all;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 70%;
    }
  }

  .order_id {
    color: #9D9EA9;
    font-weight: 800;
    margin-bottom: 10px;
  }
  .order_name {
    font-weight: 800;
    font-size: 22px;
  }
}
.select-main_block {
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;

  .select-main {
    width: 100%;
    margin-top: 10px;

    .description {
      margin: 0;
    }

    .right {
      text-align: right;
    }
  }
}

.tags_p {
  .tag {
    margin: 4px 4px;
    border: 1px dashed #e1e1e1;
    padding: 4px;
    border-radius: 5px;

    &:hover {
      border-color: red;
      cursor: pointer;
      color: red;
    }
  }
}

.product_is_set {
  padding-left: 100px;
  padding-right: 0;
  display: block;
  border-bottom: 1px dashed #e1e1e1;
}

.tiptap {
  border: 1px solid #e1e1e1;
  padding: 25px;

  &:first-child {
    margin-top: 25px;
  }

  /* List styles */

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li {
      p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }
  }

  /* Heading styles */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */

  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
  }
}

.filter-header {
  background: #f9f9f9;
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 30px;

  label {
    margin-bottom: 8px;
    color: rgba(black, 0.8);
    font-weight: 400;
  }

}


.company_search {
  background: #f9f9f9;
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.login-form {
  background: rgba(#f9f9f9, 0.7);
  border-radius: 15px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 30px 15px;
}

.app {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 15px;

  p.name {
    font-size: 16px;
    font-weight: 700;
  }

  p.description {
    font-size: 13px;
    font-weight: 400;
    color: rgba(black, 0.6);
    margin-top: 3px;
  }

  div.tags {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .apps_list-button {
    margin-top: 15px;
  }
}
@root-entry-name: default;@font-family: Gilroy;@primary-color: #2175B0;